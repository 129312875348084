import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Img from 'gatsby-image'

import '../styles/global.css'

import Header from '../components/header'
import Footer from '../components/footer'
import SEO from '../components/seo'

function ProjectTemplate({ data }) {
  function resizeComponent() {
    document.getElementById('wrapper').setAttribute('style', `height: ${window.innerHeight}px`);
  }

  React.useEffect(() => {
      resizeComponent();
      window.addEventListener('resize', resizeComponent);

      return function cleanup() {
          window.removeEventListener('resize', resizeComponent);
      }
  }, []);

  return (
    <div id='wrapper' className='page-wrapper'>
      <SEO
        title={data.strapiProject.title}
      />
      <div className='project-wrapper'>
        <Header />
        <a href={data.strapiProject.link} target='_blank'>
          <h1 className='project-title'>{data.strapiProject.title}</h1>
        </a>
        <div className='project-description'>
          <ReactMarkdown source={data.strapiProject.description} />
          <p><b>Link: </b><a href={data.strapiProject.link} target='_blank'>
          {data.strapiProject.link}
        </a></p>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default ProjectTemplate

export const query = graphql`
  query ProjectTemplate($id: String!) {
    strapiProject(id: {eq: $id}) {
      title
      description
      link
      image {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`